import '../css/style.css';
import '../css/homepage.css';
import '../css/animations.css';
import '../css/icons.css';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import CarouselWork from '../components/CarouselWork';
import myWorks from '../works';
import { SocialLinks, socialLinks } from '../social';
import HomeBanner from '../components/HomePage/Banner';
import { hardSkills, softSkills } from '../components/HomePage/Skills';
import Main from '../components/Main';
import Url from '../components/Url';
import Popup from 'reactjs-popup';
import iut from '../img/experience/iut.png';
import DetailedItem from '../components/DetailedItem';
import { useState, useEffect } from 'react';
import { getCurrentDimension } from '../utils';

const workComponents = myWorks.map(work =>
  <CarouselWork work={work} />
);

const slider = (
  <AwesomeSlider className='works-container' transitionDelay={0} >
    {workComponents.map(work => <div>{work}</div>)}
  </AwesomeSlider>

);

function About() {
  return (
    <section id="about">
      <div className="container flex-column gap-20 align-center justify-center">
        <h2>À propos de moi</h2>
        <p className='text-center'>
          Étudiant en deuxième année de BUT Informatique, je suis passionné par l'informatique,
          autant par le développement que la création, mais plus globalement la technologie dans
          son ensemble. Je suis actuellement à la recherche d'un stage dans le domaine du développement
          d'une durée de 10 semaines à compter du 15 avril 2024, ainsi qu'une alternance pour l'année
          scolaire 2024 - 2025.
        </p>
        <div className='flex gap-20'>
          <Popup trigger={<a className='button'>En savoir plus</a>} className='about-popup' arrow modal>
            {close => (
            <div className='flex-column gap-20 align-center justify-center'>
              <i className="close pointer" onClick={close}></i>
              <h3>À propos de moi</h3>
              <p className='text-justify'>
                En tant qu'étudiant en deuxième année de BUT Informatique, je suis véritablement
                immergé dans l'univers technologique, passionné par ses facettes multiples, que
                ce soit le développement, la création ou même l'ensemble des avancées qui le composent.
                En quête d'un stage de 10 semaines à compter du 15 avril 2024, ainsi que d'une alternance
                pour l'année scolaire 2024-2025, je suis déterminé à apporter ma passion et mes compétences
                à un environnement professionnel dynamique.
              </p>
              <p className='text-justify'>
                Mon appétit insatiable pour de nouvelles connaissances et terrains inexplorés me définit.
                J'ai soif d'apprendre et de m'immerger dans des domaines innovants, tout en cultivant une
                créativité débordante que j'aime exprimer et partager. Ma nature sociable m'invite constamment
                à rencontrer de nouvelles personnes et à échanger des idées, créant ainsi des synergies
                enrichissantes.
              </p>
              <p className='text-justify'>
                L'organisation est ma boussole : je m'applique à structurer et ordonner mon travail pour obtenir
                des résultats optimaux. La persévérance est ancrée en moi : je mène mes projets jusqu'au bout,
                ne renonçant jamais à relever les défis qui se dressent devant moi.
              </p>
              <p className='text-justify'>
                {"En résumé, je suis un passionné de technologie qui aspire à élargir ses connaissances et à partager sa créativité tout en s'intégrant harmonieusement dans un environnement professionnel dynamique."}
              </p>
              <a className='button-surface icon' href='https://drive.google.com/file/d/1f9pIGpPCVsj5n8ahp9uk_zhxzDOcZ7_p/view' target='_blank'>C<i class="fa fa-external-link fa-lg" aria-hidden="true"></i>onsulter mon CV</a>
            </div>
            )}
          </Popup>
          <a className='button-secondary icon' href='https://drive.google.com/file/d/1f9pIGpPCVsj5n8ahp9uk_zhxzDOcZ7_p/view' target='_blank'><i class="fa fa-external-link fa-lg" aria-hidden="true"></i>Consulter mon CV</a>
        </div>
      </div>
    </section>
  )
}

function ExperienceSchoolV1() {
  return (
    <section id="experience-school">
      <div className="container flex-column gap-50 align-center justify-center">
        <h2 className="text-center">Expérience et formation</h2>

        <div className='flex-column align-center'>
          <div className='timeline-arrow'></div>
          <div className='timeline'>
            <div className='timeline-item flex-column'>
              <p className='text-primary'>2022 - 2025</p>
              <p className='text-bold'>BUT Informatique</p>
              <p className='text-italic'>Université de Lille</p>
            </div>
            <div className='timeline-item flex-column'>
              <p className='text-primary'>2021 - 2022</p>
              <p className='text-bold'>Baccalauréat général (NSI & Maths)</p>
              <p className='text-italic'>EIC Tourcoing</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function ExperienceSchoolV2() {
  return (
    <section id="experience-school">
      <div className="container flex-column gap-100 align-center justify-center">
        <h2 className="text-center">Formation</h2>

        <div className='flex-column gap-100'>
          <DetailedItem img={iut}
            info={"2022 - 2025 • Diplôme"}
            title={"BUT Informatique"}
            description={"Préparation du diplôme du BUT dans le domaine Informatique, suivant le parcours développement d'application."}
            tags={[{ name: "Université de Lille", href: "https://www.univ-lille.fr/" }, { name: "IUT", href: "https://www.iut-a.univ-lille.fr/" }]} />

          <DetailedItem img={"https://lycee.eic-tourcoing.fr/wp-content/uploads/2022/01/EIC-LyceeEIC.jpg"}
            info={"2021 - 2022 • Diplôme"}
            title={"Baccalauréat général"}
            description={"Obtention du baccalauréat général avec mention Bien, spécialité Numérique et sciences informatiques et Mathématiques."}
            tags={[{ name: "EIC Tourcoing", href: "https://www.eic-tourcoing.fr/" }, "Mathématiques", "NSI"]} />
        </div>
      </div>
    </section>
  );
}

function Skills() {
  const [skillType, setSkillType] = useState('Hard');

  const toggle = () => {
    if (skillType === 'Hard') {
      setSkillType('Soft');
    } else {
      setSkillType('Hard');
    }
  }

  return (
    <section id="skills">
      <div className="container flex-column gap-50 align-center justify-center">
        <div className='flex-column gap-20 align-center justify-center'>
          <h2>
            <Popup className='tooltip' trigger={open => (<span><span className='pointer text-primary dotted-underline' onClick={toggle}>{skillType}</span> skills</span>)} position="left center" on={['hover', 'focus']} arrow={true} closeOnDocumentClick>
              Cliquez pour changer de catégorie
            </Popup>
          </h2>
          <p className='text-center text-italic'>Cliquez sur <span className='text-primary'>{skillType}</span> pour voir les {skillType === 'Hard' ? 'soft' : 'hard'} skills</p>
        </div>
        {skillType === 'Hard' ?
          hardSkills
        : softSkills
        }
        <a href="/skills" className="button">En savoir plus</a>

      </div>
    </section>
  );
}

function HomePage() {
  document.title = "Anas O.";

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    
    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  return (
    <Main>
      <HomeBanner />

      <About />

      <section id="works">
        <div className="container flex-column gap-20 align-center justify-center">
          <h2>Mes travaux</h2>
          <p className='text-center'>
            Voici une sélection de mes travaux les plus récents
            {screenSize.width < 530 ? ', faites défiler pour voir les autres.' : '.'}
          </p>
        </div>
        {slider}
        <a href='/works' className='button-secondary' style={{ marginTop: 80 }}>Tout voir</a>
      </section>

      <Skills />


      <ExperienceSchoolV2 />

      <section id="contact">
        <div className="container flex-column gap-50 align-center justify-center">

          <div className='flex-column gap-20 align-center justify-center'>
            <h2>Contact</h2>
            <p className='text-center'>
              Je suis joignable aussi bien par <Url href={socialLinks[2].link} colorize>mail</Url> que par <Url href={socialLinks[0].link} target="_blank" colorize>LinkedIn</Url>.
            </p>
          </div>
          <div className='flex gap-20'>
            <button className='button' onClick={() => window.open(socialLinks[2].link)}>M'envoyer un mail</button>
          </div>
        </div>
      </section>
    </Main>
  );
}

export default HomePage;
