import '../css/style.css';
import '../css/homepage.css';
import '../css/animations.css';
import '../css/icons.css';
import 'react-awesome-slider/dist/styles.css';
import Main from '../components/Main';
import Url from '../components/Url';
import { useState, useEffect } from 'react';
import { getCurrentDimension } from '../utils';

function NotFound() {
  document.title = "Page introuvable - Anas O.";

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    
    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  return (
    <Main>
      <section id="error" className="flex-column align-center justify-center  gap-20">
        <h1 className="text-center">404</h1>
        <h2 className="text-center">Page introuvable</h2>
        <p className="text-center">La page que vous recherchez n'existe pas ou a été déplacée.</p>
        <Url href="/" className="text-primary">Retour à l'accueil</Url>
      </section>
    </Main>
  );
}

export default NotFound;
