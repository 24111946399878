import mySkills from '../../skills';

function Skill(props) {
    return (
        <div class="infinite-carousel-item flex justify-center align-center">
            <div>
                <img src={props.icon} alt={props.name} />
                <p>{props.name}</p>
            </div>
        </div>
    );
}

export const hardSkills = (
    <div class="infinite-carousel-container pop">
        <div class="infinite-carousel">
            {mySkills.hard.map(skill => <Skill icon={skill.icon} name={skill.name} />)}
        </div>
    </div>
);

export const softSkills = (
    <div class="flex-wrap gap-20 justify-center align-center">
        {mySkills.soft.map(skill =>
            <div class="infinite-carousel-item flex justify-center align-center pop">
                <div>
                    <img src={skill.icon} alt={skill.name} />
                    <h5>{skill.name}</h5>
                </div>
            </div>)}
    </div>
);