const myWorks = [
    {
        img: '/assets/img/works/saiyanshooter.gif',
        date: 'Avril 2024',
        type: 'Projet scolaire',
        title: "Saiyan Shooter - Shoot'em up",
        description: "Réalisation d’un jeu-vidéo de type shoot’em up en temps réel multijoueur (en JavaScript) avec Node.js, Socket.io et Express.",
        tags: ['JavaScript', 'Node.js', 'Socket.IO', 'Express.js'],
        searchTags: ['javascript', 'node.js', 'socket.io', 'express.js', 'jeu', 'game'],
        link: '/works/saiyan-shooter',
    },
    {
        img: '/assets/img/works/pizzeria.png',
        date: 'Mars 2024',
        type: 'Projet scolaire',
        title: "API REST Pizzera",
        description: "Réalisation d’une API REST en Java (Tomcat) pour une pizzeria, permettant de gérer les commandes, les clients, les pizzas, les ingrédients...",
        tags: ['Java', 'Tomcat', 'REST'],
        searchTags: ['java', 'tomcat', 'rest', 'api', 'web'],
        link: '/works/api-pizzeria',
    },
    {
        img: '/assets/img/works/mh-menu.png',
        date: 'Décembre 2023',
        type: 'Projet scolaire',
        title: "Monster Hunter",
        description: "Réalisation d’un jeu-vidéo multijoueur (en Java) de type Monster Hunter, incluant notamment des algorithmes de pathfinding et de génération de terrain.",
        tags: ['Java', 'JavaFX'],
        searchTags: ['ihm', 'java', 'javafx', 'jeu', 'game'],
        link: '/works/monster-hunter',
    },
    {
        video: "https://cdn.discordapp.com/attachments/977610248258994226/1177423722043428935/RPReplay_Final1700782779.mp4?ex=6572744a&is=655fff4a&hm=99aa0bd7ef643e316f04b65295fef9dc7df1cc6128e31248e2e31531891e69ce&",
        date: 'Octobre 2023',
        type: 'Projet personnel',
        title: "Soccermind (application mobile)",
        description: "Réalisation d’une application mobile pour le réseau social Soccermind, permettant de partager des reviews de matchs, de joueurs, des analyses tactiques, etc.",
        tags: ['React Native'],
        searchTags: ['mobile', 'react native', 'react', 'javascript'],
        link: '/works/soccermind',
        height: 500
    },
    {
        img: '/assets/img/works/delivereat-fight.png',
        date: 'Septembre 2023',
        type: 'Projet scolaire',
        title: "Deliver'eat",
        description: "Conception et développement d’un jeu-vidéo (en Java) RPG d’un livreur de nourriture à domicile, réalisé selon la méthode Scrum en équipe de 5 personnes.",
        tags: ['Java', 'Scrum'],
        searchTags: ['ihm', 'java', 'scrum', 'jeu', 'game'],
        link: 'https://github.com/anasouh/S3.02'
    },
    {
        img: '/assets/img/works/soccermind.png',
        date: 'Août 2023',
        type: 'Projet personnel',
        title: "Soccermind",
        slug: 'soccermind',
        description: "Réalisation d’un réseau social pour les passionnés de football, permettant de partager des reviews de matchs, de joueurs, des analyses tactiques, etc.",
        tags: ['Python', 'Django', 'PostgreSQL', 'HTML', 'CSS', 'JavaScript'],
        searchTags: ['python', 'django', 'postgresql', 'html', 'css', 'web', 'javascript'],
        link: '/works/soccermind',
    },
    {
        img: '/assets/img/works/sejour-linguistique.png',
        date: 'Mai 2023',
        type: 'Projet scolaire',
        title: "Organisation de séjours linguistiques",
        description: "Réalisation d’une application (JavaFX) d’une plateforme fictive d’affectation d’un groupe d’adolescents dans le cadre d’un séjour linguistique.",
        tags: ['Java', 'JavaFX'],
        searchTags: ['ihm', 'java', 'javafx', 'logiciel'],
        link: 'https://github.com/anasouh/S2.01'
    },
];

export default myWorks;