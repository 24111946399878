import '../css/style.css';
import '../css/animations.css';
import '../css/skills.css';
import '../css/icons.css';
import 'react-awesome-slider/dist/styles.css';
import Main from '../components/Main';
import { Component, useState } from 'react';
import mySkills from '../skills';
import myWorks from '../works';
import Popup from 'reactjs-popup';
import CarouselWork from '../components/CarouselWork';
import { softSkills } from '../components/HomePage/Skills';
import Wrapper from '../components/Wrapper';

function LargeSkill(props) {

  return (
    <div className="skill-large flex align-center hover-translate pop">
      <div className="full-width full-height flex-column justify-between">
        <div className="full-width full-height flex-column gap-20">
          <div className='flex justify-between align-center'>
            <img className='skill-large-icon' src={props.icon} alt={props.name} />
          </div>
          <h3>{props.name}</h3>
          <p>{props.description}</p>
        </div>
        <div className='flex align-center gap-20'>
          <Popup trigger={<a className='button-surface'>Voir les projets</a>} modal>
            {close => (
            <div className='flex-column gap-20 align-center justify-center'>
              <i className="close pointer" onClick={close}></i>
              <h3>Projets : {props.name}</h3>
              <div className='flex-wrap gap-50 align-center justify-center'>
                {myWorks.map(work => {
                  if (work.searchTags.includes(props.name.toLowerCase()))
                    return (<CarouselWork work={work} />)
                })}
              </div>
            </div>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
}

export default function SkillsPage() {
  document.title = 'Compétences | Anas O.';

  const [type, setType] = useState('all');
  const [skillsComponents, setSkillsComponents] = useState(mySkills.hard.map(skill => {
    if (type === 'all' || skill.tags.includes(type))
      return (<LargeSkill icon={skill.icon} name={skill.name} description={skill.description} />)
  }));

  function filterSkills(type) {
    setType(type);

    const filteredSkills = mySkills.hard.filter(skill => {
      return type === 'all' || skill.tags.includes(type);
    });

    setSkillsComponents(
      filteredSkills.map(skill => (
        <LargeSkill icon={skill.icon} name={skill.name} description={skill.description} />
      ))
    );
  }

  function Filter(props) {
    return (
      <button className={"button-secondary " + ((type.toLowerCase() === props.type.toLowerCase()) ? 'active' : '')} onClick={() => filterSkills(props.type)}>{props.label}</button>
    )
  }

  return (
    <Main>

      <section id="hard-skills">
        <div className="container flex-column gap-50 align-center justify-center">
          <h2>Mes compétences</h2>
          <div className="flex-wrap gap-20 align-center justify-center">
            <Filter type="all" label="Tous" />
            <Filter type="développement" label="Développement" />
            <Filter type="db" label="Base de données" />
            <Filter type="mobile" label="Apps mobiles" />
            <Filter type="web" label="Web" />
          </div>
            <Wrapper className="skill-large-container" max={6}>
              {skillsComponents}
            </Wrapper>
        </div>
      </section>

      <section id="soft-skills">
        <div className="container flex-column gap-50 align-center justify-center">
          <h2>Mes aptitudes</h2>
          {softSkills}
        </div>
      </section>

    </Main>
  );
}