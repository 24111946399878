import { Route, useLocation } from "react-router-dom";
import Main from "../components/Main";
import "../css/article.css"
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import NotFound from "./NotFound";

function fill() {
    const article = document.getElementById("article");
    const title = article.querySelector("h1");
    const seeAlso = document.querySelector(".article-side-content#see-also>div");
    const tableOfContents = document.getElementById("table-of-contents");
    const sections = article.querySelectorAll("h3");

    if (title) {
        document.title = title.innerText + " - Anas O.";
    }

    sections.forEach(section => {
        const li = document.createElement("li");
        const a = document.createElement("a");
        a.href = "#" + section.id;
        a.innerText = section.innerText;
        li.appendChild(a);
        tableOfContents.querySelector("ul").appendChild(li);
    });
    
    const seeAlsoElts = document.querySelector(".article-content #see-also");
    if (seeAlsoElts) {
        seeAlsoElts.childNodes.forEach(elt => {
            seeAlso.appendChild(elt.cloneNode(true));
        });
        seeAlsoElts.remove();
    }

    const images = article.querySelectorAll("img"); 
    images.forEach(img => {
        img.parentNode.style.textAlign = "center";
        img.parentNode.style.maxWidth = "100%";
        const caption = document.createElement("span"); 
        caption.className = "img-caption";
        caption.innerText = img.alt;
        img.parentNode.appendChild(caption);
    });
}

export default function ArticlePage({article}) {
    const [content, setContent] = useState(null);
    const [error, setError] = useState(false);
    const articleName = useLocation().pathname.split("/").pop();

    useEffect(() => {
        fetch('/documents/articles/' + articleName + '.md')
            .then(res => {
                if (res.headers.get("Content-Type").startsWith("text/markdown")) {
                    return res.text();
                } else {
                    setError(true);
                }
            })
            .then(data => setContent(data))
            .catch(error => {
                setError(true);
            });
    }, [articleName]);

    useEffect(() => {   
        if (content) {
            fill();
        }
    }, [content]);

    return (
        error ? <NotFound /> :
            <Main>
                <section id="article">
                    <div className="article flex-row-responsive gap-20 justify-center">
                        <div className="article-side">
                            <div className="article-side-content" id="table-of-contents">
                                <h6 className="sidetitle">Table des matières</h6>
                                <ul>
                                </ul>
                            </div>
                        </div>
                        <div className="article-content">                        
                            <Markdown rehypePlugins={[rehypeRaw]}>{content}</Markdown>
                        </div>
                        <div className="article-side">
                            <div className="article-side-content" id="see-also">
                                <div className="flex-column gap-20 align-center justify-center">
                                    <h6 className="sidetitle">Voir également</h6>
                                
                                </div>  
                            </div>
                        </div>
                    </div>
                </section>
            </Main>
    )
}