export default function HomeBanner() {
    return (
        <section id="banner">
            <div className="banner pop">
                <div className='flex-column gap-20'>
                    <h1 className='text-extrabold'>Anas Ouhdda</h1>
                    <p>Étudiant en BUT Informatique</p>
                    <a href='/#about' className='button-secondary'>En savoir plus</a>
                </div>
                <div>
                    <figure className="me morph"></figure>
                </div>
            </div>
        </section>
    )
}