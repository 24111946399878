export default function DetailedItem(props) {
    return (
        <div className="work pop">
            
                {
                    props.img != null ?
                        <div className="work-img">
                            <img src={props.img} alt={props.title} style={props.height ? {height: props.height, objectFit: 'contain'} : {}}/>
                        </div>
                        : <video autoPlay loop muted playsInline height={props.height} style={{borderRadius: 15}} className="work-video">
                            <source src={props.video} type="video/mp4" />
                        </video>
                }
            
            <div className="work-content">
                <span className="work-infos">{props.info}</span>
                {
                    props.link ?
                    <a href={props.link} target="_blank"><h4>{props.title}</h4></a>
                    : <h4>{props.title}</h4>
                }
                <p>{props.description}</p>
                <ul className="work-tags">
                    {props.tags.map(tag => 
                        typeof tag === "string" ?
                            <a><li>{tag}</li></a>
                           : <a href={tag.href} target="_blank"><li>{tag.name}</li></a>
                    )
                    }
                </ul>
            </div>
        </div>
    )
}