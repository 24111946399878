export const socialLinks = [
    {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/in/anas-ouhdda-181797291',
        icon: 'https://img.icons8.com/fluent/48/000000/linkedin.png'
    },
    {
        name: 'GitHub',
        link: 'https://github.com/anasouh',
        icon: 'https://img.icons8.com/fluent/48/000000/github.png'
    },
    {
        name: 'Gmail',
        link: 'mailto:an.ouhdda@gmail.com',
        icon: 'https://img.icons8.com/fluent/48/000000/gmail.png'
    }
];

const socialComponents = socialLinks.map(social =>
    <a href={social.link} target="_blank">
        <img src={social.icon} alt={social.name} />
    </a>
);

export function SocialLinks() {
    return (
        <div className='social-links'>
            {socialComponents}
        </div>
    );
}