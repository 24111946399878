import { SocialLinks, socialLinks } from '../social';
import Header from './Header';

export default function Main(props) {
    return (
        <div className="main">
            <Header />
            <div className="content flex-column align-center">
                {props.children}

                <footer className='footer'>
                    <div className="container full-width flex-column align-center justify-center gap-20">
                        <div className="container full-width flex align-center justify-between">
                            <p>© {new Date().getFullYear()} • <a href="https://github.com/anasouh" target="_blank">Anas Ouhdda</a></p>
                            <SocialLinks />
                        </div>
                        <p>
                            Créé par <a href="https://github.com/anasouh" target="_blank">Anas Ouhdda</a>
                        </p>
                    </div>
                </footer>
            </div>
        </div>
    );
}