import PropTypes from "prop-types";
import Popup from "reactjs-popup";

const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive">
        <iframe
            width="853"
            height="454"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
);

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default function CarouselWork({work}) {
    return (
        <div className="work pop">

            {
                work.img != null ?
                    <div className={"work-img" + ((work.link && work.link != "#") | (work.slug !== undefined) ? " pointer" : "")}>
                        <img src={work.img} alt={work.title} style={work.height ? { height: work.height, objectFit: 'contain' } : {}} onClick={work.link ? () => window.open(work.link) : work.slug ? () => window.location.href = `/works/${work.slug}` : null} />
                    </div>
                    : <video autoPlay loop muted playsInline height={work.height} style={{ borderRadius: 15 }} className="work-video">
                        <source src={work.video} type="video/mp4" />
                    </video>
            }

            <div className="work-content">
                <span className="work-infos">{work.date} • {work.type}</span>
                {
                    work.link && work.link !== "#" ?
                        <Popup className='tooltip' trigger={open => (<a className="max-content" href={work.link} target={(work.link.startsWith('/')) ? '' : '_blank'}><h4 className="max-content">{work.title}</h4></a>)} position="right center" on={['hover', 'focus']} arrow={true} closeOnDocumentClick>
                            Voir le projet
                        </Popup>
                        : 
                        work.slug ? <a href={`/works/${work.slug}`}><h4>{work.title}</h4></a> :
                        <Popup trigger={<a className="pointer"><h4>{work.title}</h4></a>} className='about-popup' modal>
                            {close => (
                                <div className='flex-column gap-20 align-center justify-center'>
                                    <i className="close pointer" onClick={close}></i>
                                    <p>Aucune version publique de ce projet n'est disponible pour le moment, contactez moi et je me ferai un plaisir de vous le présenter !</p>
                                </div>
                            )}</Popup>
                }
                            <p>{work.description}</p>
                            <ul className="work-tags">
                                {work.tags.map(tag =>
                                    <a href="/skills"><li>{tag}</li></a>
                                )
                                }
                            </ul>
                        </div>
        </div>
            )
}