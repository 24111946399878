import '../css/style.css';
import '../css/works.css';
import '../css/animations.css';
import '../css/icons.css';
import 'react-awesome-slider/dist/styles.css';
import CarouselWork from '../components/CarouselWork';
import myWorks from '../works';
import Main from '../components/Main';
import { useState } from 'react';

export default function WorksPage() {
  document.title = 'Travaux | Anas O.';
  
  const [type, setType] = useState('all');
  const [workComponents, setWorkComponents] = useState(myWorks.map(work => {
    if (type === 'all' || work.searchTags.includes(type))
      return (<CarouselWork work={work} />)
  }));

  function filterWorks(type) {
    setType(type);
    setWorkComponents(myWorks.map(work => {
      if (type === 'all' || work.searchTags.includes(type))
        return (<CarouselWork work={work} />)
    }));
  }

  function Filter(props) {
    return (
      <button className={"button-secondary " + ((type.toLowerCase() === props.type.toLowerCase()) ? 'active' : '')} onClick={() => filterWorks(props.type)}>{props.label}</button>
    )
  }

  return (
    <Main>

      <section id="works">
        <h2>Mes travaux</h2>
        <div className="container flex gap-20 align-center justify-center">
          <div className="flex-wrap gap-20 align-center justify-center">
            <Filter type="all" label="Tous" />
            <Filter type="logiciel" label="Logiciels" />
            <Filter type="jeu" label="Jeux" />
            <Filter type="mobile" label="Apps mobiles" />
            <Filter type="web" label="Web" />
          </div>
        </div>
        <div className="container flex-column gap-100 align-center" style={{ marginTop: 100 }}>
          {workComponents}
        </div>
      </section>

    </Main>
  );
}