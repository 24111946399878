import { Component } from 'react';

class Wrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        length: this.props.max,
        showMore: false
      };
    }
  
    render() {
      return (
        <div className="flex-column gap-50 align-center justify-center">
          <div className={this.props.className}>
            {this.props.children.slice(0, this.state.showMore ? this.props.children.length : this.state.length)}
          </div>
          <div className="flex align-center justify-center gap-20">
            <button className="button-secondary" onClick={() => this.setState({ length: this.state.showMore ? this.props.max : this.state.length + 3, showMore: !this.state.showMore })}>{this.state.showMore ? 'Voir moins' : 'Voir plus'}</button>
          </div>
        </div>
      );
    }
  }

export default Wrapper;